import { useState, useEffect } from "react";
import axiosHelper from "../helpers/axiosHelper";
import { useHistory, Link } from "react-router-dom";
import { longDateAndTimeString } from "../helpers/utils";
// Media
import logoEvent15 from "../img/logotransp.png";

import LogoImageStudi from '../img/02-studi/logo-small-traced.svg';
import LogoImageEnterprise from '../img/01-enterprise/logo-small-traced.svg';
import LogoImagePlatinum from '../img/02-swp/logo-small-traced.svg';

interface Props {
  showLogo: boolean;
}

const Header = (props: Props) => {
  const history = useHistory();
  const [logoApp, setLogoApp] = useState<string>();

  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );


  return (
    <div className="container">
      <div className="row pt-2 pb-2 white text-uppercase fw-bolder top-header">
        <div
          className={`order-2 order-md-1  ${
            userProfile == null
              ? "col-6 col-md-3 d-flex align-items-center"
              : "col-6 col-md-3 d-flex align-items-center"
          } justify-content-start`}
        >
        </div>
        <div
          className={`order-1 order-md-2 col-12  ${
            userProfile == null
              ? "col-md-6 d-flex align-items-center justify-content-center"
              : "col-md-6 d-flex align-items-center justify-content-center justify-content-md-end justify-content-xl-center"
          } pb-1 pb-md-0 event-date`}
        >
          Corso Alta Formazione
        </div>
        <div
          className={`order-3 order-md-3 ${
            userProfile == null ? "col-6 col-md-3" : "col-6 col-md-3"
          } d-flex align-items-center justify-content-end`}
        >
          {/* <img
            src={logoEvent15}
            height="28px"
            alt="MMBA"
            className="logo-ts"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Header;

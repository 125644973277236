import React, { useState, useEffect, Fragment } from 'react';
import Navigation from '../components/Navigation';
import axiosHelper from '../helpers/axiosHelper';
import Footer from '../components/Footer';
import { useHistory } from 'react-router-dom';
// import Sound from "react-sound";

//Media
// import LogoImage from "../img/logo-big-traced.svg";
import LogoImageStudi from '../img/02-studi/logo-big-traced.svg';
import LogoImageEnterprise from '../img/01-enterprise/logo-big-traced.svg';
import LogoImagePlatinum from '../img/02-swp/logo-big-traced.svg';

// Audio Track
// import audioIconOn from "../img/audio_on.svg";
// import audioIconOff from "../img/audio_off.svg";
// import helpdeskAudioTrack from "../audio/benvenuto.mp3";

interface InfoContent {
  id: number;
  title: string;
  content1: string;
  content2: string;
  content3: string;
}

const InfoDesk = () => {
  const [logoImage, setLogoImage] = useState<string>();

  useEffect(() => {

    axiosHelper.logWebAppEvent('HelpDesk', null, null);
  }, []);


  const InfoItem: React.FC<InfoContent> = (props: InfoContent) => {
    const { title, content1, content2 } = props;
    const [content, setContent] = useState<string>('');


    return (
      <div className='row info-row'>
        <div className='col-12 pt-4 ps-3'>
          <div className='info-description large pb-2' dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    );
  };

  const infoItems: InfoContent[] = [
    {
      id: 1,
      title: 'Benvenuto',
      content1: "Benvenuto al TalkS Aziende 2022!<br/><br/>E’ un piacere per noi fornirti tutte le informazioni necessarie per vivere al meglio questo appuntamento.<br/><br/>Scopri di più sull’evento!<br/><br/>All’interno di questa piattaforma avrai la possibilità di:<br/><ul><li>Scoprire l’agenda della giornata</li><li>Scoprire chi saranno i nostri relatori</li><li>Accedere alla sala plenaria in cui potrai seguire l'evento live, interagire direttamente con noi attraverso la chat e chiedere informazioni e chiarimenti</li></ul><br/>I lavori inizieranno alle ore 10.30, sarà possibile accedere alla sala plenaria pochi minuti prima dell’inizio dell’evento.<br/><br/>Buona visione!<br/>",
      content2: "Benvenuto al TalkS Studi 2022!<br/><br/>E’ un piacere per noi fornirti tutte le informazioni necessarie per vivere al meglio questo appuntamento.<br/><br/>Scopri di più sull’evento!<br/><br/>All’interno di questa piattaforma avrai la possibilità di:<br/><ul><li>Scoprire l’agenda della giornata</li><li>Scoprire chi saranno i nostri relatori</li><li>Accedere alla sala plenaria in cui potrai seguire l'evento live, interagire direttamente con noi attraverso la chat e chiedere informazioni e chiarimenti</li></ul><br/>I lavori inizieranno alle ore 10.30, sarà possibile accedere alla sala plenaria pochi minuti prima dell’inizio dell’evento.<br/><br/>Buona visione!<br/>",
      content3: "Benvenuti all'evento commerciale dedicato ai Software Partner.<br/><br/>E’ un piacere per noi fornirti tutte le informazioni necessarie per vivere al meglio questo appuntamento.<br/><br/>Scopri di più sull’evento!<br/><br/>All’interno di questa piattaforma avrai la possibilità di:<br/><ul><li>Scoprire l’agenda della giornata</li><li>Scoprire chi saranno i nostri relatori</li><li>Accedere alla sala plenaria in cui potrai seguire l'evento live, interagire direttamente con noi attraverso la chat e chiedere informazioni e chiarimenti</li></ul><br/>I lavori inizieranno alle ore 15.00, sarà possibile accedere alla sala plenaria pochi minuti prima dell’inizio dell’evento.<br/><br/>Buona visione!<br/>",
    },
    {
      id: 2,
      title: 'Numeri utili',
      content1: "Se durante la mattina dovessi riscontrare difficoltà di connessione o qualsiasi altro problema tecnico scrivi a <a href='mailto:segreteria@teamsystemdigitalevents.com'>segreteria@teamsystemdigitalevents.com</a> oppure contattaci al numero <a href='tel:0250041583'>0250041583</a>.<br/><br/>Per le richieste di assistenza la Segreteria sarà attiva dal 18 Ottobre dalle 09:00 alle 12:00.",
      content2: "Se durante la mattina dovessi riscontrare difficoltà di connessione o qualsiasi altro problema tecnico scrivi a <a href='mailto:segreteria@teamsystemdigitalevents.com'>segreteria@teamsystemdigitalevents.com</a> oppure contattaci al numero <a href='tel:0250041583'>0250041583</a>.<br/><br/>Per le richieste di assistenza la Segreteria sarà attiva dal 18 Ottobre dalle 09:00 alle 12:00.",
      content3: "Se durante la mattina dovessi riscontrare difficoltà di connessione o qualsiasi altro problema tecnico scrivi a <a href='mailto:segreteria@teamsystemdigitalevents.com'>segreteria@teamsystemdigitalevents.com</a> oppure contattaci al numero <a href='tel:0250041583'>0250041583</a>.<br/><br/>Per le richieste di assistenza la Segreteria sarà attiva dal 18 Ottobre dalle 13:30 alle 16:30.",
    },
  ];

  const [currentInfo, setCurrentInfo] = useState<InfoContent>(infoItems[0]);

  return (
    <Fragment>
      {/* <Sound url={helpdeskAudioTrack} playStatus={audioStatus} loop={true} /> */}
      <div className={`page-wrapper page-infodesk`}>
        <div className='page-container'>
          <div className='page-content'>
            <div className='top-area'>
              <Navigation showLogo={false} />
            </div>

            <div className='center-area'>
              <div className='container'>
                <div className='row justify-content-center infodesk-wrapper position-relative px-2 p-md-0'>
                  <div className='col-12'>
                    <div className='row'>
                      <div className='col-12 d-flex justify-content-center tww-container'>
                        <img className='tww-image-small d-none d-md-block' src={logoImage} alt='Logo Evento' />
                      </div>
                    </div>

                    {/* Begins infodesk content */}

                    <div className='row justify-content-center'>
                      <div className='col-12 2 col-lg-8 white-box mb-5'>
                        <div className='row'>
                          <div className='col-12 col-md-3 col-lg-3 left-box pe-0'>
                            <div className='items-list'>
                              <div className='items-list-scroller'>
                                {infoItems.map((info) => (
                                  <div
                                    className={`info-tab py-2 px-0 d-flex flex-row justify-content-between ${info.id === currentInfo.id ? 'active' : ''}`}
                                    key={info.id}
                                    onClick={() => {
                                      console.log('click');
                                      setCurrentInfo(info);
                                      // if (info.id == 1) {
                                      //   if (axiosHelper.soundInWelcome()) {
                                      //     setAudioStatus("PLAYING");
                                      //   } else {
                                      //     setAudioStatus("PAUSED");
                                      //   }
                                      // } else {
                                      //   setAudioStatus("PAUSED");
                                      // }
                                    }}>
                                    <div className='pt-1 flex-grow-1 ps-1'>
                                      <div className='tab-title text-start pb-1'>{info.title}</div>
                                    </div>
                                    <div className='arrow'></div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-9 col-lg-9 right-box p-3 white infodesk-bg position-relative'>
                            {/* <div className="info-audio">
                              {currentInfo.id != 1 ? (
                                ""
                              ) : audioStatus === "PAUSED" ? (
                                <img
                                  src={audioIconOff}
                                  alt="Audio On"
                                  onClick={() => {
                                    axiosHelper.setSoundInWelcome(true);
                                    setAudioStatus("PLAYING");
                                  }}
                                />
                              ) : (
                                <img
                                  src={audioIconOn}
                                  alt="Audio Off"
                                  onClick={() => {
                                    axiosHelper.setSoundInWelcome(false);
                                    setAudioStatus("PAUSED");
                                  }}
                                />
                              )}
                            </div> */}
                            <InfoItem {...currentInfo} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='bottom-area'>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InfoDesk;

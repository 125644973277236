import React, { useEffect, useState } from 'react';
import RedflixItemComp from './RedflixItemComp';
import iconPlay from '../../img/redflix-play-blue.svg';
import ProgressBar from '../ProgressBar';
import { useHistory } from 'react-router-dom';
import ProgressBarMini from '../ProgressBarMini';

interface Props {
  category: LibraryCategory;
}

const RedflixCategoryComp = (props: Props) => {
  const [videoItem, setVideoItem] = useState<LibraryContent>();
  const [otherItems, setOtherItems] = useState<LibraryContent[]>([]);
  const history = useHistory();

  const { category } = props;

  useEffect(() => {
    console.log(category);
    category.items.forEach((item) => {
      if (item.type === 'V') {
        setVideoItem(item);
      } else {
        var previous = [...otherItems];
        previous.push(item);
        setOtherItems(previous);
      }
    });
  }, []);

  const synopsisMaker = (text: string) => {
    if (text) {
      const array = text.split('\n');
      return (
        <ul>
          {array.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      );
    }
    return null;
  };
  const addLeadingZero = (value: Number) => {
    let valueString = String(value);
    while (valueString.length < 2) {
      valueString = '0' + valueString;
    }
    return valueString;
  };

  const durationMaker = (seconds: number) => {
    if (seconds) {
      let diff = seconds;
      const timeLeft = {
        mins: 0,
        secs: 0,
      };

      if (diff >= 60) {
        timeLeft.mins = Math.floor(diff / 60);
        diff -= timeLeft.mins * 60;
      }
      timeLeft.secs = Math.floor(diff);
      return (
        <>
          {addLeadingZero(timeLeft.mins)}:{addLeadingZero(timeLeft.secs)}
        </>
      );
    }
    return null;
  };

  const goSee = (category: LibraryCategory) => {
    if (videoItem) {

      history.push('/lesson/' + category.id);
    }
  };
  return (
    <div className='lesson-row-wrapper'>
      <h2>{category.name}</h2>
      <div className='lesson-row row'>
        {videoItem != null && (
          <>
            <div className='col-10 lesson-item-wrapper' onClick={() => goSee(category)}>
              <div className='lesson-title'>{videoItem.name}</div>
              <div className='lesson-teacher'>{videoItem.starring}</div>
            </div>
            {videoItem.bunnyUrlPath && (
              <div className='col-2 lesson-tools'>
                <div className='play' onClick={() => history.push('/lesson/' + category.id)}>
                  <img src={iconPlay} className='icon' alt='Play' />
                  <div className='lesson-duration'>durata {durationMaker(videoItem.duration)}</div>
                  <ProgressBarMini bgcolor='#005388' completed={Math.floor((videoItem.lastSeconds / videoItem.duration) * 100)} />
                </div>
              </div>
            )}
            <div className='lesson-synopsis'>{synopsisMaker(videoItem.synopsis)}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default RedflixCategoryComp;

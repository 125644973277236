import React from 'react';
import Constants from '../../helpers/constants';
import axiosHelper from '../../helpers/axiosHelper';

//Media
import iconPlay from '../../img/redflix-play.svg';
import iconDownload from '../../img/redflix-download.svg';

interface Props {
  item: LibraryContent;
  onPlay: (item: LibraryContent) => void;
}

const RedflixItemComp = (props: Props) => {

  const logDownload = () => {
    axiosHelper.logWebAppEvent('RedFlix', item.id, 'DOWNLOAD');

    return true;
  };
  const { item, onPlay } = props;

  return (
    <div className='content-item-wrapper'>
      <div>
        <a className='title' download rel='noreferrer' target='_blank' href={item.bunnyUrlPath}>
          {item.name}
        </a>
        <a className='sub-title' download rel='noreferrer' target='_blank' href={item.bunnyUrlPath}>
          {item.synopsis}
        </a>
        <div className='tools'>
          <a className='download' download rel='noreferrer' target='_blank' onClick={() => logDownload()} href={item.bunnyUrlPath}>
            <img src={iconDownload} className='icon' alt='Download' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default RedflixItemComp;

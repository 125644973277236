import { useEffect, useState } from 'react';
import axiosHelper from '../../helpers/axiosHelper';
import { chatTimeString, isBefore } from '../../helpers/utils';
import { useHistory } from 'react-router-dom';
import TestStore, { createSubmission, fetchSubmission, getSubmission } from '../../helpers/TestStore';
import { useStoreState } from 'pullstate';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const TestExecutor = () => {
  const submission = useStoreState(TestStore, getSubmission);
  const history = useHistory();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const startTest = () => {
    createSubmission.run().then((result) => {
      if (result.error) {
        console.log('THERE WAS AN ERROR');
      } else {
        history.push('/test');
      }
    });
  };
  const continueTest = () => {
    history.push('/test');
  };
  const showTestResults = () => {
    history.push('/results');
  };
  useEffect(() => {
    fetchSubmission.run();
  }, []);

  return (
    <div>
      <h2>Verifica Competenze</h2>
      {axiosHelper.profileCache?.mmbaSubmissions.length === 0 ? (
        <>
          {isBefore(new Date(Date.parse(axiosHelper.profileCache?.events[0].showDate))) ? (
            <>
              <p>È disponile il test sui vari moduli formativi</p>
              <p>Consiste di 30 domande a risposta multipla da svolgere nel tempo massimo di 40 minuti</p>
              <p>Una volta avviato, il testo dovrà essere completato senza possibilità di interruzione</p>
              <div className='text-center'>
                <br />
                <button className='button--submit' onClick={handleShow}>
                  AVVIA
                </button>
              </div>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Attenzione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Il test, una volta iniziato, non potrà essere interrotto
                  <br /> Avrete 40 minuti per completare le 30 domande a risposta multipla.
                </Modal.Body>
                <Modal.Footer>
                  <Button className='button--ghost' onClick={handleClose}>
                    Annulla
                  </Button>
                  <Button className='button--submit' onClick={startTest}>
                    AVVIA TEST
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <>
              <p>Il tempo per eseguire il test sui vari moduli formativi è terminato.</p>
            </>
          )}
        </>
      ) : (
        <>
          {submission?.state === 'N' && (
            <>
              <p>
                Test <span>IN CORSO</span>
              </p>
              <p>Test avviato {chatTimeString(submission!.startDate!)}</p>
              <div className='text-center'>
                <br />
                <button className='button--submit' onClick={continueTest}>
                  CONTINUA IL TEST
                </button>
              </div>
            </>
          )}
          {submission?.state === 'A' && (
            <>
              <p>
                Test <span>IN CORSO</span>
              </p>
              <p>Test avviato {chatTimeString(submission!.startDate!)}</p>
              <div className='text-center'>
                <br />
                <button className='button--submit' onClick={continueTest}>
                  CONTINUA IL TEST
                </button>
              </div>
            </>
          )}
          {submission?.state === 'E' && (
            <>
              <p>
                Test <span>TERMINATO</span>
              </p>
              <p>Punteggio test {submission!.score!}</p>
              <div className='text-center'>
                <br />
                <button className='button--submit' onClick={showTestResults}>
                  VISUALIZZA RISULTATI
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default TestExecutor;

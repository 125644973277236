import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory } from 'react-router-dom';
import axiosHelper from '../helpers/axiosHelper';
import ModalBox from '../components/ModalBox';
import Constants from '../helpers/constants';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// Media
import LogoImage from '../img/logotransp.png';

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

type LoginData = {
  username: string;
  password: string;
  rememberme: boolean;
};

const Login = () => {
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [busy, setBusy] = useState(false);
  const { register, handleSubmit, errors } = useForm<LoginData>({
    criteriaMode: 'all',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const onSubmit = async (data: LoginData) => {
    setBusy(true);
    try {
      const result = await axiosHelper.login<UserProfile>(data.username, data.password);
      axiosHelper.setJntToken(result.data.jntToken, data.rememberme);
      axiosHelper.saveUserProfile(result.data);
      setBusy(false);
      // if (result.data.isSpeaker && result.data.avatarURL === 'empty.jpg') {
      //   history.push('/photobooth');
      // } else {
        history.push('/library');
      // }
    } catch (error:any) {
      setBusy(false);
      if (error.response == null) {
        setLoginErrorMessage('Errore di comunicazione con il Backend');
        setError(true);
      } else {
        switch (error.response.status) {
          case 417:
            setLoginErrorMessage("Troppi tentativi di login errati, l'account è stato disabilitato, riprovare dopo 10 minuti");
            setError(true);
            break;
          case 401:
            setLoginErrorMessage('Email o password errati');
            setError(true);
            break;
          default:
            setLoginErrorMessage('Email o password errati');
            setError(true);
            break;
        }
      }
    }
  };

  // if (storageHelper.userIsLogged()) {
  //     history.push("/");
  // }
  return (
    <div className='page-wrapper page-login'>
      <div className='page-container'>
        <div className='page-content'>
          <div className='top-area'>
            <Header showLogo={false} />
          </div>
          <div className='center-area'>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-12 col-md-8 col-lg-6 position-relative'>
                  <div className='row'>
                    <div className='col-12 d-flex justify-content-center tww-container'>
                      <img className='tww-image' src={LogoImage} alt='TALKS' />
                    </div>
                  </div>
                  <div className='row px-2 p-md-0'>
                    <div className='col-12 white-box pt-3 pb-2 px-3'>
                      <div className='row'>
                        <div className='col-12'>
                          <h2 className='text-title pb-2'>Accedi:</h2>
                          <div className='text-body'>Accedi inserendo la tua e-mail aziendale e la password scelta</div>
                        </div>
                      </div>

                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <input
                              autoComplete='username'
                              type='email'
                              placeholder='E-mail'
                              name='username'
                              ref={register({
                                required: 'Campo obbligatorio' as string,
                                max: 100,
                                min: 4,
                                maxLength: 100,
                              })}
                            />
                            <ErrorMessage errors={errors} name='username' />
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='pass-wrapper'>
                              <input
                                type={passwordShown ? 'text' : 'password'}
                                autoComplete='current-password'
                                placeholder='Password'
                                name='password'
                                className='mb'
                                ref={register({
                                  required: 'Campo obbligatorio' as string,
                                })}
                              />
                              <i onClick={togglePasswordVisiblity}>{passwordShown ? eyeSlash : eye}</i>
                            </div>
                            <ErrorMessage errors={errors} name='password' />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-6 d-flex justify-content-start align-items-center'>
                            <div className='mb-3 mb-lg-0'>
                              <a onClick={() => history.push('/passwordreset')}>Password dimenticata?</a>
                            </div>
                          </div>
                          <div className='col-md-3 d-flex justify-content-start align-items-center'>
                            <div className='checkbox-row'>
                              <input type='checkbox' placeholder='Ricordami' id="remembercheck" name='rememberme' ref={register} />
                              <label htmlFor="remembercheck" className='small'>Ricordami</label>
                            </div>
                          </div>
                          <div className='col-md-3 d-flex justify-content-start align-items-center'>{!busy && <button className='button--submit'>Accedi</button>}</div>
                        </div>

                        {error ? <div className='modal-text error-message'>{loginErrorMessage}</div> : null}
                      </form>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'></div>
                    <div className='col-md-6 d-flex justify-content-between'>
                      <div className='help-link'>
                        <a onClick={() => setModalVisible(!modalVisible)}>Aiuto</a>
                        <ModalBox visible={modalVisible} onClose={() => setModalVisible(!modalVisible)} title={'Aiuto'}>
                          Se dovessi riscontrare difficoltà di connessione o qualsiasi altro problema tecnico scrivi a <a href='mailto:support@altera.it'>support@altera.it</a>.<br />
                        </ModalBox>
                      </div>
                      <div className='help-link'>
                        <a href={Constants.INFORMATIVA_PRIVACY_LINK} target='_blank'>
                          Informativa privacy
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='bottom-area'>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

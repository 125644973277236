import React, { useState, useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import axiosHelper from './helpers/axiosHelper';

import Activate from './screens/Activate';
import Error404Page from './screens/Error404Page';
import Logout from './screens/Logout';
import Login from './screens/Login';
import PasswordReset from './screens/PasswordReset';
import InfoDesk from './screens/InfoDesk';
import ThankYou from './screens/ThankYou';
import RedFlix from './screens/Redflix';
import RedflixDetailComp from './components/redflix/RedflixDetailComp';
import Test from './screens/Test';

const Container = ({ location }: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    console.log('wlf2');
    // console.log('USE EFFECT DI CONTAINER');
    const retrieveUserProfile = async () => {
      try {
        const result = await axiosHelper.loginWithToken<UserProfile>();
        console.log(result.data);
        if (result.data !== null) {
          axiosHelper.saveUserProfile(result.data);
          // console.log('SETTO LOGGED IN');
          setIsLoggedIn(true);
        } else {
          // console.log('LOGN ERR ');
          setIsLoggedIn(false);
        }
      } catch (e) {
        console.log(e);
        setIsLoggedIn(false);
      }
    };
    if (axiosHelper.isLoggedIn) {
      // console.log('LOGGATO IN');
      setIsLoggedIn(true);
    } else if (axiosHelper.hasToken) {
      // console.log('HA IL TOKEN RETRIEVO');
      retrieveUserProfile();
    } else {
      // console.log('NON HO IL TOKEN NE SONO LOGGATO');
      setIsLoggedIn(false);
    }
    return () => {};
  });

  if (isLoggedIn == null) {
    return <div />;
  } else {
    return (
      <Switch>
        <Route exact path='/activate/:token' component={Activate} />
        <Route exact path='/resetpass/:token' component={Activate} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/passwordreset' component={PasswordReset} />
        {isLoggedIn ? (
          <>
            <Route exact path='/' component={RedFlix} />
            <Route exact path='/thankyou' component={RedFlix} />
            <Route exact path='/test' component={Test} />
            <Route exact path='/results' component={Test} />
            <Route exact path='/library' component={RedFlix} />
            <Route exact path='/logout' component={Logout} />
            <Route exact path='/helpdesk' component={InfoDesk} />
            <Route exact path='/lesson/:categoryId' component={RedflixDetailComp} />
          </>
        ) : (
          <>
            <Route exact path='/' component={Login} />
            <Route exact path='/thankyou' component={Login} />
            <Route exact path='/test' component={Login} />
            <Route exact path='/results' component={Login} />
            <Route exact path='/library' component={Login} />
            <Route exact path='/helpdesk' component={Login} />
            <Route exact path='/lesson/:categoryId' component={RedflixDetailComp} />
          </>
        )}
        <Route path='/*' component={Error404Page} />
      </Switch>
    );
  }
};

export default withRouter(Container);

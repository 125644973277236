import React, { useState, useEffect, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Header from '../components/Header';
import Footer from './Footer';

import iconClose from '../img/close-button.svg';
import iconOpen from '../img/open-button.svg';
import logoEvent15 from '../img/logotransp.png';
import axiosHelper from '../helpers/axiosHelper';
import Test from '../screens/Test';
import TestCountdown from './test/TestCountdown';
import TestStore from '../helpers/TestStore';

interface NavItemProps {
  routeName: string;
  route: string;
}

interface Props {
  showLogo: boolean;
}

const Navigation = (props: Props) => {
  const [userProfile, setUserProfile] = useState<UserProfile>(axiosHelper.getUserProfile()!);

  const [menusDisabled, setMenusDisabled] = useState(false);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    // var interval: any;
    console.log('NAVIGATION USE EFFECT');

    return () => {
      console.log('NAVIGATION CLEANUP');
    };
  }, []);

  const handleNavToggle = (event: React.MouseEvent<HTMLElement>) => {
    console.log('TOGFG');
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const renderStaticNavigationToggle = (opened: boolean) => {
    if (!menusDisabled) {
      return (
        <div className='navigation-toggle' onClick={(event) => handleNavToggle(event)}>
          {opened ? <img src={iconClose} className='nav-toggle-close' alt='close' /> : <img src={iconOpen} className='nav-toggle-open' alt='open' />}
        </div>
      );
    }
  };

  const NavItem = (props: NavItemProps) => {
    const { route, routeName } = props;
    const handleNavigation = (route: string) => history.push(route);
    return (
      <div className='nav-item-container pb-4' onClick={() => handleNavigation(route)}>
        <div className='nav-item-handle large ps-4'>{routeName}</div>
      </div>
    );
  };

  const NavHeader = () => {
    return (
      <>
        <div className='header'>
          <div className='container'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 nav-utils py-1 pb-0'>
                  <div className='row d-flex justify-content-between align-items-center'>
                    {/* Mobile */}
                    <div className='col-6 nav-left d-block d-lg-none pt-2'>
                      <TestCountdown />
                    </div>
                    {!menusDisabled && (
                      <>
                        <div className='col-6 nav-left d-none d-lg-block'>
                          <div className='small thin salutation'>Ciao,</div>
                          <div className='userprofile-name d-inline'>
                            {userProfile.firstName} {userProfile.lastName}
                          </div>
                          {props.showLogo && (
                            <Link to='/logout' className='logout small'>
                              Esci
                            </Link>
                          )}
                        </div>

                        <div className='col-6 nav-right d-none d-lg-flex align-items-center justify-content-end pe-0'>
                          <TestCountdown />
                        </div>
                        <div className='col-6 nav-right d-flex d-lg-none align-items-center justify-content-end pe-0'>{!menusDisabled && <>{renderStaticNavigationToggle(false)}</>}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <NavHeader />
      <div className={`navigation-wrapper ${isOpen ? 'open' : 'close'}`}>
        <div className='navigation-container d-flex flex-column'>
          <div className='nav-logo d-flex flex-row justify-content-end pe-4'>{renderStaticNavigationToggle(true)}</div>

          <div className='mobile-header ps-3 pb-5'>
            {userProfile.firstLogin ? (
              <>
                <div className='small thin salutation'>Ciao,</div>
                <div className='userprofile-name d-inline large fw-bold pe-2'>
                  {userProfile.firstName} {userProfile.lastName}
                </div>
              </>
            ) : (
              <>
                <div className='small thin salutation'>Ciaone,</div>
                <div className='userprofile-name d-inline large fw-bold pe-2'>
                  {userProfile.firstName} {userProfile.lastName}
                </div>
              </>
            )}
          </div>

          <div className='nav-navigation pt-2 flex-grow-1'>
            <NavItem routeName='Logout' route='/logout' />
          </div>
          <div className='d-flex align-items-center justify-content-between pb-2 ps-2 fw-bold'>{/* {eventDate?.toUpperCase()} */}</div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Navigation;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import axiosHelper from '../helpers/axiosHelper';

const Logout = () => {
  const history = useHistory();

  const performLogout = async () => {
    try {
      await axiosHelper.logout();
    } catch (e) {
      console.error(e);
    } finally {
      axiosHelper.clearUserProfile()
      history.push('/login');

    }
  }
const sendLogoutEvent = async ()=>{
  try {
   await axiosHelper.logWebAppEvent('Logout', null, null);
    
  }catch(e){
    console.log(e);
  } finally {
    performLogout();
  }

}
  useEffect(() => {
    sendLogoutEvent();
        
    console.log('LOGGING OUT');
    // performLogout();
    window.scrollTo(0, 0);
  }, []);

  return <></>
};

export default Logout;

import { useState } from 'react';
import AnswerComponent from './AnswerComponent';

interface Props {
  question: Question;
}
const QuestionComponent = (props: Props) => {
  const [containerClass, setContainerClass] = useState('question-container');
  const setHasAnswer = () => {
    setContainerClass((oldClass) => oldClass + ' has-answer');
  };
  return (
    <div className={containerClass}>
      <div className='question-title'>Domanda {props.question.pos}</div>
      <div className='question-question'>{props.question.questionText}</div>
      {props.question.mmbaAnswers.map((answer: Answer) => (
        <AnswerComponent key={answer.id} answer={answer} hasAnswerCallback={setHasAnswer}/>
      ))}
    </div>
  );
};
export default QuestionComponent;

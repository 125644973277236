import { useReducer } from "react"

import { BrowserRouter as Router } from "react-router-dom"
import Container from "./Container"
import { MainProvider } from "./context"
import reducer from "./reducer"


// import "slick-carousel/slick/slick-theme.css"
// import "slick-carousel/slick/slick.css"
import "./App.scss"

function App() {
  const [state] = useReducer(reducer, {
    isLoading: false,
    isLoggedIn: false,
  })

  return (
    <MainProvider value={state}>
      <Router>
        <Container />
      </Router>
    </MainProvider>
  )
}

export default App

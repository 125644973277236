import React, { useState, useEffect } from "react"
// Media
import closeIcon from "../img/icon-close-white.svg"
interface Props {
  visible: boolean
  title?: string
  text?: string
  children?: any
  onClose?: () => void
}

const Modalconfirm = (props: Props) => {
  let { visible, text, title, onClose } = props
  const [isVisible, setIsVisible] = useState<Boolean>(visible)

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (onClose) onClose()
    else {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    setIsVisible(visible)
  }, [visible])
  if (visible)
    return (
      <div
        className={`modalbox-wrapper d-flex flex-column justify-content-center align-items-center ${
          isVisible ? "visible" : "hidden"
        }`}
      >
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-8 col-lg-6 d-flex flex-column white-box">
              <div className="row p-3">
                <div className="col-10 p-0 text-start">
                  <h2 className="text-title">{title}</h2>
                </div>
                <div className="col-2 p-0 text-end d-flex justify-content-end align-items-center">
                  <a
                    href="#"
                    className="close-button d-flex justify-content-end align-items-center"
                    onClick={(e) => handleClose(e)}
                  >
                    <img src={closeIcon} alt="Close" />
                  </a>
                </div>
              </div>

              <div className="flex-grow-1">
                <div className="row">
                  <div className="col-12">{text}</div>
                  <div className="col-12 modalbox-content pb-3">
                    {props.children}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex px-0 rainbow">
                  <div className="rc-1"></div>
                  <div className="rc-2"></div>
                  <div className="rc-3"></div>
                  <div className="rc-4"></div>
                  <div className="rc-5"></div>
                  <div className="rc-6"></div>
                  <div className="rc-7"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  else return null
}

export default Modalconfirm

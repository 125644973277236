import React, { useEffect, useState } from 'react';
import RedflixItemComp from './RedflixItemComp';
import iconPlay from '../../img/redflix-play.svg';
import iconBack from '../../img/back-arrow-navigation-svgrepo-com.svg';
import Navigation from '../Navigation';
import VideoModal from './VideoModal';
import axiosHelper from '../../helpers/axiosHelper';
import { useHistory } from 'react-router-dom';
import ProgressBarMini from '../ProgressBarMini';

const RedflixDetailComp = ({ match }: any) => {
  const [videoItem, setVideoItem] = useState<LibraryContent>();
  const [showProgress, setShowProgress] = useState(false);
  const [otherItems, setOtherItems] = useState<LibraryContent[]>([]);
  const [currentVideo, setCurrentVideo] = useState<LibraryContent | null>(null);
  const [playervisible, setPlayerVisible] = useState<boolean>(false);
  const [category, setCategory] = useState<LibraryCategory>();
  const history = useHistory();

  const handleVideoPlay = (video: LibraryContent) => {
    console.log('APRO VIDEO');
    console.log(JSON.stringify(video));

    axiosHelper.logWebAppEvent('Library', video.id, 'PLAY');
    setCurrentVideo(video);
    setPlayerVisible(true);
    setShowProgress(false);
  };

  const handleVideoClose = () => {
    axiosHelper.logWebAppEvent('Library', currentVideo!.id, 'CLOSE');
    setCurrentVideo(null);
    setPlayerVisible(false);
  };
  const fetch = async (id: string) => {
    try {
      //const result = fetch('data/21b95eb5-543f-48a0-9cdf-ea3f90a21c78.json');
      const result = await axiosHelper.fetchRedflixCategory(id);
      if (result != null) {
        // console.log("GOT THE REDFLIX LIST");
        // console.log(result);
        setCategory(result);
      }
    } catch {
      // console.log('ERRORE NEL TIRAR GIU I DATI');
    }
  };

  useEffect(() => {
    let catId = match.params.categoryId;
    console.log(catId);
    // axiosHelper.getRedflixCategory(catId);
    fetch(catId);
  }, [match.params.categoryId]);

  useEffect(() => {
    if (category) {
      category.items.forEach((item) => {
        console.log('ITEM');
        console.log(item);
        if (item.type === 'V') {
          setVideoItem(item);
          setShowProgress(true);
        } else {
          var previous = [...otherItems];
          previous.push(item);
          setOtherItems(previous);
        }
      });
    }
  }, [category]);

  return (
    <>
      <Navigation showLogo={false} />
      <div className='page-wrapper'>
        <div className='page-container'>
          <div className='page-content'>
            <div
              className='back-area'
              onClick={() => {
                history.goBack();
              }}>
              <img src={iconBack} alt='thumbnail' />&nbsp;
              indietro
            </div>

            <div className='center-area'>
              <div className='columns-wrapper'>
                {category && (
                  <div className='box col-12 redflix-wrapper'>
                    <div className='video-row-wrapper'>
                      <h2>{category.name}</h2>
                      <div className='video-row'>
                        {videoItem != null && (
                          <div className='video-item-wrapper' onClick={() => handleVideoPlay(videoItem)}>
                            <img src={videoItem.bunnyThumbUrlPath} alt='thumbnail' />
                            <div className='title'>{videoItem.name}</div>
                            <div className='sub-title'>{videoItem.starring}</div>
                            <div className='tools'>
                              <div className='play' onClick={() => handleVideoPlay(videoItem)}>
                                <img src={iconPlay} className='icon' alt='Play' />
                              </div>
                            </div>
                            {showProgress && <ProgressBarMini bgcolor='#005388' completed={Math.floor((videoItem.lastSeconds / videoItem.duration) * 100)} />}
                          </div>
                        )}
                        {otherItems.map((item: LibraryContent) => (
                          <RedflixItemComp item={item} key={item.id} onPlay={handleVideoPlay} />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='bottom-area'></div>
          </div>
        </div>
      </div>
      {currentVideo !== null ? <VideoModal item={currentVideo} visible={playervisible} onClose={handleVideoClose} /> : null}
    </>
  );
};

export default RedflixDetailComp;

import { createAsyncAction, Store, successResult } from 'pullstate';
import { createSelector } from 'reselect';

import axiosHelper from '../helpers/axiosHelper';
import { calculateUserAnswers } from './utils';
export interface ITestStore {
  testActive: boolean;
  submission?: Submission;
  targetDate?: Date;
}

const TestStore = new Store<ITestStore>({
  testActive: false,
});

export const toggleChecked = (answerId: number, value: boolean) => {
  TestStore.update((s) => {
    if (s.submission) {
      const newQuestions = s.submission.questions.map((question) => {
        let uncheckAll = false;
        const answer = question.mmbaAnswers.find((a) => a.id === answerId);
        if (answer && !value) {
          uncheckAll = true;
        }
        const newAnswers = question.mmbaAnswers.map((answer) => {
          if (answer.id === answerId) {
            return { ...answer, checked: !value };
          } else {
            if (uncheckAll) {
              return { ...answer, checked: false };
            } else {
              return answer;
            }
          }
        });
        return { ...question, mmbaAnswers: newAnswers };
      });
      s.submission = { ...s.submission, questions: newQuestions };
      axiosHelper.sendSubmission(s.submission).then();
    }
  });
};

export const createSubmission = createAsyncAction(async () => {
  const value = await axiosHelper.startTest();
  if (value) {
    TestStore.update((s) => {
      s.submission = value;
      if (value.state === 'E') {
        s.testActive = false;
      } else {
        s.testActive = true;
        const targetDate = new Date(new Date(Date.parse(value.startDate)).getTime() + 40 * 60 * 1000);
        if (s.targetDate !== targetDate) s.targetDate = targetDate;
      }
    });
  }
  return successResult();
});
export const fetchSubmission = createAsyncAction(async () => {
  if (axiosHelper.profileCache)
    if (axiosHelper.profileCache.mmbaSubmissions.length > 0) {
      const value = await axiosHelper.downloadSubmission(axiosHelper.profileCache!.mmbaSubmissions[0].id!);

      if (value) {
        TestStore.update((s) => {
          s.submission = value;
          if (value.state === 'E') {
            s.testActive = false;
          } else {
            if (!s.testActive) {
              console.log('WAS NOT ACTIVE... ');
              s.testActive = true;
              const targetDate = new Date(new Date(Date.parse(value.startDate)).getTime() + 40 * 60 * 1000);
              if (s.targetDate !== targetDate) {
                console.log('RESETTING DATE');
                s.targetDate = targetDate;
              }
            }
          }
        });
      }
    }
  return successResult();
});
export const sendSubmission = createAsyncAction(async (submission: Submission) => {
  const value = await axiosHelper.sendSubmission({ ...submission, state: 'E' });

  if (value) {
    TestStore.update((s) => {
      s.submission = value;
      if (value.state === 'E') {
        s.testActive = false;
      } else {
        const targetDate = new Date(new Date(Date.parse(value.startDate)).getTime() + 40 * 60 * 1000);
        if (s.targetDate !== targetDate) {
          s.targetDate = targetDate;
        }
      }
    });
  }
  return successResult();
});
export default TestStore;

const getTestStore = (state: ITestStore) => state;

export const getSubmission = createSelector(getTestStore, (state) => state.submission);

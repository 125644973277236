import React, { useEffect, useState } from 'react';
import TestStore from '../../helpers/TestStore';
interface ICounter {
  mins: number;
  secs: number;
}

const TestCountdown = () => {
  const testActive = TestStore.useState((s) => s.testActive);
  const endDate = TestStore.useState((s) => s.targetDate);

  const [counter, setCounter] = useState<ICounter>({ mins: 0, secs: 0 });
  useEffect(() => {
    console.log('USE EFFECT');
  }, []);

  useEffect(() => {
    console.log('USE EFFECT' + testActive);
  }, [testActive]);

  useEffect(() => {
    console.log('USE EFFECT' + endDate);
  }, [endDate]);

  useEffect(() => {
    console.log('COUNTDOWN');
    const calculateCountDown = () => {
      const timeLeft = { mins: 0, secs: 0 };

      if (endDate) {
        const now = new Date();

        let diff = (endDate.getTime() - now.getTime()) / 1000;

        if (diff <= 0) return timeLeft;

        if (diff >= 60) {
          timeLeft.mins = Math.floor(diff / 60);
          diff -= timeLeft.mins * 60;
        }
        timeLeft.secs = Math.floor(diff);
      }
      return timeLeft;
    };

    const interval = setInterval(() => {
      console.log('setInterval');
      setCounter(calculateCountDown());
    }, 1000);
    return function cleanup() {
      console.log('clear');
      clearInterval(interval);
    };
  }, [endDate]);

  const addLeadingZero = (value: Number) => {
    let valueString = String(value);
    while (valueString.length < 2) {
      valueString = '0' + valueString;
    }
    return valueString;
  };

  if (testActive && counter) {
    return (
      <div className={`countdown-container`}>
        Tempo per completare il Test {addLeadingZero(counter.mins)}:{addLeadingZero(counter.secs)}
      </div>
    );
  } else {
    return null;
  }
};

export default TestCountdown;

import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import RedflixCategoryComp from '../components/redflix/RedflixCategoryComp';
import VideoModal from '../components/redflix/VideoModal';
import Constants from '../helpers/constants';
import axiosHelper from '../helpers/axiosHelper';
import TestExecutor from '../components/test/TestExecutor';
import { isAfter, isBefore, longDateAndTimeStringFromDate, longDateStringFromDate } from '../helpers/utils';

const RedFlix = () => {
  const [playervisible, setPlayerVisible] = useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState<boolean>(!axiosHelper.alertShown);
  const [currentVideo, setCurrentVideo] = useState<LibraryContent | null>(null);
  const [redflixCategories, setRedflixCategories] = useState<LibraryCategory[]>([]);
  const [courseStarted, setCourseStarted] = useState(true);
  const [courseEnded, setCourseEnded] = useState(false);
  const [startDate, setStartDate] = useState(new Date(Date.parse(axiosHelper.profileCache!.events[0].fromDate)));
  const [endDate, setEndDate] = useState(new Date(Date.parse(axiosHelper.profileCache!.events[0].toDate)));
  const [event] = useState(axiosHelper.profileCache?.events[0]);

  const fetchRedflix = async () => {
    try {
      const result = await axiosHelper.getRedflixCategories();
      if (result != null) {
        if (result.length === 0) {
          // setCourseEnded(true);
        }
        setRedflixCategories(result);
      }
    } catch {
      // console.log('ERRORE NEL TIRAR GIU I DATI');
    }
  };
  const checkCourseAvailability = () => {
    console.log(startDate);
    console.log(isBefore(startDate));
    console.log(isAfter(endDate));
    if (isBefore(startDate)) {
      setCourseStarted(false);
    } else {
      if (isAfter(endDate)) {
        setCourseEnded(true);
      } else {
        fetchRedflix();
      }
    }
  };
  useEffect(() => {
    console.log();
    checkCourseAvailability();
    axiosHelper.logWebAppEvent('Library', null, null);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (redflixCategories) {
      axiosHelper.saveRedflixCategories(redflixCategories);
    }
  }, [redflixCategories]);

  const handleVideoPlay = (video: LibraryContent) => {
    console.log('APRO VIDEO');
    console.log(JSON.stringify(video));
    axiosHelper.logWebAppEvent('Library', video.id, 'PLAY');
    setCurrentVideo(video);
    setPlayerVisible(true);
  };

  const handleVideoClose = () => {
    // console.log('CHIUDO VIDEO');
    axiosHelper.logWebAppEvent('Library', currentVideo!.id, 'CLOSE');
    setCurrentVideo(null);
    setPlayerVisible(false);
  };
  const closeAlert = () => {
    axiosHelper.hideAlert();
    setAlertVisible(false);
  };

  return (
    <>
      <Navigation showLogo={true} />
      <div className='page-wrapper'>
        <div className='page-container'>
          <div className='page-content'>
            <div className='center-area'>
              <div className='columns-wrapper'>
                <div className='box col-12 lessons-wrapper'>
                  {courseEnded ? (
                    <div className='alert alert-info' role='alert'>
                      La Fruizione del Master è terminata {longDateStringFromDate(endDate)}
                      <br />
                      <br />
                      {axiosHelper.profileCache?.userType === 'T' || axiosHelper.profileCache?.userType === 'S' ? (
                        <>
                          <TestExecutor />
                        </>
                      ) : (
                        <div>Grazie per la partecipazione</div>
                      )}
                    </div>
                  ) : (
                    <>
                      {courseStarted ? (
                        <>
                          {alertVisible && (
                            <div className='alert alert-danger alert-dismissible' role='alert'>
                              <strong>ATTENZIONE – {longDateStringFromDate(endDate)}</strong> Termine Fruizione Master
                              <button type='button' className='btn-close' data-bs-dismiss='alert' aria-label='Close' onClick={closeAlert}></button>
                            </div>
                          )}

                          {redflixCategories.map((category: LibraryCategory) => (
                            <RedflixCategoryComp category={category} key={category.id} />
                          ))}
                        </>
                      ) : (
                        <div className='alert alert-info' role='alert'>
                          La Fruizione del Master sarà possibile a partire da <strong>{longDateStringFromDate(startDate)}</strong>
                          <br />
                          <br />
                          <div>a presto!</div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className='bottom-area'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedFlix;

import { useState, useEffect, useRef } from 'react';
import Player from './Player';
import videojs from 'video.js';
import axiosHelper from '../helpers/axiosHelper';

const PlayerWrapper = (props) => {
  const [seenSeconds, setSeenSeconds] = useState(0);
  const sentDate = useRef(0);
  const playerRef = useRef(null);
  const videoJsOptions = {
    autoplay: true,
    controls: true,
    controlBar: {
      pictureInPictureToggle: true,
      subsCapsButton: false,
      progressControl: true,
      remainingTimeDisplay: true,
    },
    responsive: true,
    fluid: true,
  };

  useEffect(() => {
    const now = new Date().getTime();
    if (now - sentDate.current > Math.floor(Math.random() * 15000) + 10000) {
      sentDate.current = now;
      axiosHelper.sendSeconds(props.libraryContentId, seenSeconds);
    }
  }, [seenSeconds, props.libraryContentId]);

  const handlePlayerReady = (player) => {
    console.log('handleplayerready');
    playerRef.current = player;
    player.currentTime(props.startAtSeconds)
    player.src({
      src: `${props.bunnyUrlPath}`,
      type: 'application/x-mpegURL',
    });

    player.on('pause', () => {
      console.log('pause');
      sentDate.current = sentDate.current = 0;
      axiosHelper.sendSeconds(props.libraryContentId, player.currentTime());
    });

    player.on('ended', () => {
      console.log('ended');
      sentDate.current = new Date().getTime();
      axiosHelper.sendSeconds(props.libraryContentId, player.currentTime());

      if (props.closeCall) {
        setTimeout(() => {
          props.closeCall();
        }, 2000);
      }
    });
    player.on('timeupdate', () => {
      setSeenSeconds(player.currentTime());
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  return <Player options={videoJsOptions} onReady={handlePlayerReady} />;
};

export default PlayerWrapper;

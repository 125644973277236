import React from 'react';
interface Props {
  bgcolor: string;
  completed: number;
}

const ProgressBarMini = (props: Props) => {
  let { bgcolor, completed } = props;

  const containerStyles = {
    height: 8,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 4,
    margin: 0,
  };

  const fillerStyles = {
    height:'100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    transition: 'width 1s ease-in-out',
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
  };

  // if (completed < 10) {
  //   return null;
  // }
  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{}</span>
      </div>
    </div>
  );
};

export default ProgressBarMini;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import axiosHelper from "../helpers/axiosHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Header from "../components/Header";
import Footer from "../components/Footer";

// Media
import LogoImage from '../img/logotransp.png';

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

interface FormData {
  password: string;
  confirmpassword: string;
}
interface UserData {
  id: number;
  objecttype: string;
  email: string;
  firstName: string;
  language: string;
  lastName: string;
  userType: string;
}

const Activate = ({ match }: any) => {
  const history = useHistory();
  const [isExpired, setIsExpired] = useState(false);
  const [isReset] = useState(history.location.pathname.startsWith("/reset"));
  const [token, setToken] = useState<string>("");
  const [data, setData] = useState<UserData>();
  const [activated, setActivated] = useState<boolean>(false);
  const { handleSubmit, register, errors, watch } = useForm<FormData>();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const savePassword = async (password: string) => {
    if (data?.id !== undefined) {
      try {
        const result = await axiosHelper.savePassword<UserProfile>(
          data!.id,
          password,
          token
        );

        if (result.data.jntToken !== undefined) {
          setActivated(true);
        }
      } catch (error) {
        //TODO gestire il caso...
      }
    }
  };

  const onSubmit = (values: FormData) => {
    if (values.password === values.confirmpassword) {
      savePassword(values.password);
    }
  };

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setToken(match.params.token);
    const fetchData = async () => {
      // console.log(token);
      if (token !== "") {
        try {
          if (isReset) {
            const result = await axiosHelper.reset(token);
            setData(result.data);
          } else {
            const result = await axiosHelper.activate(token);
            setData(result.data);
          }
        } catch (error:any) {
          if (error.response?.status === 401) {
            history.push("/");
          }
          if (error.response?.status === 410) {
            setIsExpired(true);
          }
          //setError(error);
        }
      }
    };
    fetchData();
    window.scrollTo(0, 0);
  }, [match.params.token, token, history, isReset]);

  return (
    <>
      <div className="page-wrapper page-login">
        <div className="page-container">
          <div className="page-content">
            <div className="top-area">
              <Header showLogo={false} />
            </div>
            <div className="center-area">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 col-lg-6 position-relative">
                    {isExpired ? (
                      <>
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center tww-container">
                            <img
                              className="tww-image"
                              src={LogoImage}
                              alt="MMBA"
                            />
                          </div>
                        </div>
                        <div className="row px-2 p-md-0">
                          <div className="col-12 white-box pt-3 pb-2 px-3">
                            <div className="row">
                              <div className="col-12">
                                <h2 className="text-title primary pb-2">
                                  Attenzione
                                </h2>
                                <div className="text-body">
                                  <div className="info">
                                    Il tuo link per cambiare la passeword è
                                    scaduto
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-9 d-flex justify-content-start align-items-center"></div>
                              <div className="col-md-3 d-flex justify-content-start align-items-center">
                                <Link to="/login" className="button">
                                  LOGIN
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : activated === false ? (
                      <>
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center py-3 py-md-5">
                            <img
                              className="tww-image"
                              src={LogoImage}
                              alt="MMBA"
                            />
                          </div>
                        </div>
                        <div className="row px-2 p-md-0">
                          <div className="col-12 white-box pt-3 pb-2 px-3">
                            <div className="row">
                              <div className="col-12">
                                <h2 className="text-title primary pb-2">
                                  {isReset ? (
                                    <h2>Scegli una nuova password</h2>
                                  ) : (
                                    <h2>Scegli la tua password</h2>
                                  )}
                                </h2>
                                <div className="text-body">
                                  <div className="info">
                                    Ricorda: la password deve essere lunga
                                    almeno 8 caratteri e contenere un numero.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <input
                                autoComplete="username"
                                type="hidden"
                                name="email"
                                value={data?.email}
                              ></input>
                              <div className="row">
                                <div className="col-12 col-md-6">
                                  <div className="pass-wrapper">
                                    <input
                                      type={passwordShown ? "text" : "password"}
                                      autoComplete="new-password"
                                      placeholder="Inserisci la password"
                                      onChange={() => console.log("")}
                                      ref={register({
                                        required: "Inserisci password",
                                        pattern: {
                                          //value:  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[|\-_\\0123456789!@#&()\[{}\]:;',?/*~$^+=<>]).{8,}$/gm,
                                          value:  /^(?=.*\d).{8,}$/gm,
                                          message:
                                            "Password troppo debole, attieniti alle indicazioni in alto.",
                                        },
                                      })}
                                      name="password"
                                    ></input>
                                    <i onClick={togglePasswordVisiblity}>
                                      {passwordShown ? eyeSlash : eye}
                                    </i>
                                  </div>
                                  <span className="error-message">
                                    {errors.password && errors.password.message}
                                  </span>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="pass-wrapper">
                                    <input
                                      type={passwordShown ? "text" : "password"}
                                      autoComplete="new-password"
                                      placeholder="Ripeti la password"
                                      ref={register({
                                        validate: (value) =>
                                          value === watch("password") ||
                                          "Le password devono corrispondere",
                                      })}
                                      // onChange={(event) => console.log(event.target)}
                                      name="confirmpassword"
                                    ></input>
                                    <i onClick={togglePasswordVisiblity}>
                                      {passwordShown ? eyeSlash : eye}
                                    </i>
                                  </div>
                                  {/* <ErrorMessage errors={errors} name="password" /> */}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 d-flex justify-content-start align-items-center">
                                  {isReset ? (
                                    <button className="button--submit">
                                      {" "}
                                      Invia{" "}
                                    </button>
                                  ) : (
                                    <button className="button--submit">
                                      {" "}
                                      Crea Password{" "}
                                    </button>
                                  )}
                                </div>
                              </div>
                              {/* {error ? (
                                    <div className="modal-text error-message">
                                        {loginErrorMessage}
                                    </div>
                                    ) : null} */}
                            </form>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center py-3 py-md-5">
                            <img
                              className="tww-image"
                              src={LogoImage}
                              alt="MMBA"
                            />
                          </div>
                        </div>
                        <div className="row px-2 p-md-0">
                          <div className="col-12 white-box pt-3 pb-2 px-3">
                            <div className="row">
                              <div className="col-12">
                                <h2 className="text-title primary pb-2">
                                  Grazie per aver creato la tua password!
                                </h2>
                                <div className="text-body">
                                  <div className="info">
                                    Clicca sul pulsante qui sotto e procedi con
                                    il login utilizzando la tua e-mail aziendale
                                    e la password che hai appena scelto.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8 d-flex justify-content-start align-items-center"></div>
                              <div className="col-md-4 d-flex justify-content-start align-items-center">
                                <Link to="/login" className="button--submit">
                                  Vai al login
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-area">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Activate;

import React, { useState, useEffect } from 'react';
import axiosHelper from '../helpers/axiosHelper';


const Footer = () => {
  const [userProfile, setUserProfile] = useState<UserProfile>(axiosHelper.getUserProfile()!);

  return null;
};

export default Footer;

import React, { useState, useEffect } from 'react';
import iconClose from '../../img/icon-close-white.svg';
import Constants from '../../helpers/constants';
import PlayerWrapper from '../PlayerWrapper';
var crypto = require('crypto');

interface Props {
  item: LibraryContent;
  visible: boolean;
  onClose?: () => void;
}

const VideoModal = (props: Props) => {
  const { item, visible, onClose } = props;
  const [isVisible, setIsVisible] = useState<Boolean>(visible);

  const startSeconds = () => {
    console.log('START SECONDS');
    console.log(item.duration);
    console.log(item.lastSeconds);
    if (item.duration - item.lastSeconds > 20) return item.lastSeconds;
    else return 0;
  };

  // Generate the URL
  console.log(item);
  console.log(item.duration);
  console.log(item.lastSeconds);
  console.log(item.duration - item.lastSeconds);

  const handleClose = () => {
    if (onClose) onClose();
    else {
      setIsVisible(false);
    }
  };

  if (isVisible)
    return (
      <div className='video-modal-wrapper'>
        <div className='modal-close' onClick={() => handleClose()}>
          <img src={iconClose} alt='Close' />
        </div>
        <PlayerWrapper libraryContentId={item.id} bunnyUrlPath={item.bunnyUrlPath} startAtSeconds={startSeconds()} closeCall={handleClose} />{' '}
      </div>
    );

  return null;
};

export default VideoModal;

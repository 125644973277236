import { fas } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { idText } from 'typescript';
import Constants from './constants';
import { calculateUserAnswers, isOlderThanSeconds } from './utils';
import { generateUniqueID } from './utils';
import RedflixCategoryComp from '../components/redflix/RedflixCategoryComp';

const JNT_TOKEN = 'jntToken';
const SOUND_IN_HALL = 'sih';
const SOUND_IN_WELCOME = 'siw';
const UNIQUE_ID = 'uniqueID';

interface WebAppLog {
  pageName: string;
  idLibraryContent: number;
  event: string;
}
class AxiosHelper {
  alertShown = false;
  eventId = 0;
  isLoggedIn = false;
  hasToken = false;
  hasAttendeesCache = false;
  attendeesCache: Attendee[] = [];
  attendeesCacheDate = new Date();
  hasEventStatusCache = false;
  eventStatusCache: EventStatus | null = null;
  eventStatusCacheDate = new Date();
  // hasRoomsCache = false;
  // roomsCache: ChatRoom[] = [];
  // roomsCacheDate = new Date();
  duringEvent = false;
  redflixCategories: LibraryCategory[] = [];

  constructor() {
    this.getUniqueID();
    let token = localStorage.getItem(JNT_TOKEN);
    if (token !== null) {
      this.setJntToken(token, true);
      // this.retrieveUserProfile();
      // this.isLoggedIn = true;
    }
  }
  hideAlert() {
    console.log('SET ALERT SHOWN');
    this.alertShown = true;
  }
  sendSeconds(lcid: number, seconds: number) {
    axios.put(`${Constants.API_ENDPOINT}/LibraryContent/${lcid}`, { seenSeconds: seconds }).then(
      (resp) => {
        console.log('RESPONSE');
        console.log(resp);
      },
      (reason) => {
        console.log('ERROR');
        console.log(reason);
      }
    );
  }

  login<UserProfile>(username: string, password: string) {
    localStorage.removeItem(JNT_TOKEN);
    var bodyFormData = new FormData();
    bodyFormData.append('e', username);
    bodyFormData.append('p', password);
    return axios
      .post<UserProfile>(`${Constants.API_ENDPOINT}/Attendee/login`, bodyFormData)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  requestSignature<RequestSignatureResult>(fileName: string, mimeType: string, type: string) {
    var bodyFormData = new FormData();
    bodyFormData.append('fileName', fileName);
    bodyFormData.append('mimeType', mimeType);
    bodyFormData.append('type', type);
    return axios
      .post<RequestSignatureResult>(`${Constants.API_ENDPOINT}/Attendee/requestSignature`, bodyFormData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  setUploadSuccessful(uploadId: string) {
    const finishAndUpdateProfile = async (bodyFormData: FormData) => {
      let res = await axios.post<UserProfile>(`${Constants.API_ENDPOINT}/Attendee/uploadFinished`, bodyFormData);
      console.log('occhio a res');
      console.log(res);
      this.saveUserProfile(res.data);
    };
    var bodyFormData = new FormData();
    bodyFormData.append('uploadId', uploadId);
    finishAndUpdateProfile(bodyFormData);
  }

  logout() {
    return axios(`${Constants.API_ENDPOINT}/Attendee/logout`);
  }

  activate(token: string) {
    return axios(`${Constants.API_ENDPOINT}/Attendee/activate?tmpToken=${token}`);
  }

  reset(token: string) {
    return axios(`${Constants.API_ENDPOINT}/Attendee/reset?tmpToken=${token}`);
  }

  resetPassword(email: string) {
    return axios(`${Constants.API_ENDPOINT}/Attendee/resetPassword?email=${email}`);
  }

  checkAccount<UserProfile>() {
    return axios.get<UserProfile>(`${Constants.API_ENDPOINT}/Attendee/check`);
  }

  loginWithToken<UserProfile>() {
    if (axios.defaults.headers.common['jntToken']) {
      return axios
        .get<UserProfile>(`${Constants.API_ENDPOINT}/Attendee/tokLogin`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.clearUserProfile();
          }
          throw error;
        });
    } else {
      throw 'no token';
    }
  }

  getRedflixCategories() {
    return axios
      .get<LibraryCategory[]>(`${Constants.API_ENDPOINT}/LibraryCategory`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });

    // return require('./21b95eb5-543f-48a0-9cdf-ea3f90a21c78.json');
  }
  fetchRedflixCategory(id: string) {
    return axios
      .get<LibraryCategory>(`${Constants.API_ENDPOINT}/LibraryCategory/` + id)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });

    // return require('./21b95eb5-543f-48a0-9cdf-ea3f90a21c78.json');
  }

  saveRedflixCategories(redflixCategories: LibraryCategory[]) {
    console.log('setting categories');
    console.log(redflixCategories);
    this.redflixCategories = redflixCategories;
  }
  getRedflixCategory(catId: number) {
    console.log('getting category ' + catId);
    console.log(this.redflixCategories);
    const result = this.redflixCategories.find((category) => category.id === catId);
    console.log(result);
    if (!result) {
      return this.redflixCategories[0];
    }
    return result;
    // return this.redflixCategories.find((cat) => cat.id === catId);
  }

  logWebAppEvent(pageName: string | null, idLibraryContent: number | null, event: string | null) {
    return axios.post(`${Constants.API_ENDPOINT}/WebAppLog`, { pageName, idLibraryContent, event });
  }

  savePassword<UserProfile>(id: number, password: string, token: string) {
    return axios.put<UserProfile>(
      `${Constants.API_ENDPOINT}/Attendee/${id}`,
      {
        cleartextPassword: password,
      },
      {
        headers: { tmpToken: token },
      }
    );
  }

  updateAccount(profile: UserProfile) {
    return axios.put<UserProfile>(`${Constants.API_ENDPOINT}/Attendee/${profile.id}`, profile);
  }

  setJntToken(token: string, permanently: boolean) {
    this.hasToken = true;
    axios.defaults.headers.common['jntToken'] = token;
    if (permanently) {
      localStorage.setItem(JNT_TOKEN, token);
    }
  }
  setClientId(id: string) {
    axios.defaults.headers.common['clientId'] = id;
  }

  registerClientData(data: UserProfile, clientId: string) {
    // console.log('AXIOS registerClientData ' + data);
    let { jntToken } = data;
    axios.defaults.headers.common['jntToken'] = jntToken;
    axios.defaults.headers.common['clientId'] = clientId;
  }

  savePermanently = false;
  profileCache: UserProfile | null = null;
  submissionCache: Submission | null = null;

  getRelatori(id: number) {
    return axios.get<Relatore[]>(`/relatori/${id}.json`);
  }
  getUserProfile(): UserProfile | null {
    // console.log("##########################GET USER PROFILE");
    if (this.profileCache !== null) {
      return this.profileCache;
    }
    return null;
  }

  saveUserProfile(data: UserProfile) {
    // console.log('saveUserProfile');
    // console.log(data);
    if (data !== null) {
      this.isLoggedIn = true;
    }
    this.profileCache = data;
  }

  clearUserProfile() {
    // localStorage.clear();
    localStorage.removeItem(JNT_TOKEN);
    this.eventId = 0;
    this.isLoggedIn = false;
    this.profileCache = null;
    this.savePermanently = false;
  }

  getUniqueID(): string {
    let result = localStorage.getItem(UNIQUE_ID);
    if (result !== null) {
      this.setClientId(result);
      return result;
    } else {
      const id = generateUniqueID('jnt-');
      this.saveUniqueID(id);
      return id;
    }
  }
  saveUniqueID(id: string) {
    localStorage.setItem(UNIQUE_ID, id);
    this.setClientId(id);
  }

  startTest() {
    return axios
      .post<Submission>(`${Constants.API_ENDPOINT}/MMBASubmission`)
      .then((response) => {
        console.log(response.data);
        this.submissionCache = response.data;
        return response.data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }

  sendSubmission(submission: Submission) {
    return axios
      .put<Submission>(`${Constants.API_ENDPOINT}/MMBASubmission/${submission.id}`, { state: submission.state, response: JSON.stringify(calculateUserAnswers(submission)) })
      .then((response) => {
        console.log(response);
        if (response.data) {
          this.submissionCache = response.data;
          return response.data;
        }
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }

  downloadSubmission(subid: number) {
    return axios
      .get<Submission>(`${Constants.API_ENDPOINT}/MMBASubmission/${subid}`)
      .then((response) => {
        console.log(response);
        if (response.data) {
          this.submissionCache = response.data;
          return response.data;
        }
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }
}

export default new AxiosHelper();

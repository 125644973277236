import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import axiosHelper from "../helpers/axiosHelper";
import Constants from "../helpers/constants";

import Header from "../components/Header";
import Footer from "../components/Footer";

// Media
import LogoImage from '../img/logotransp.png';

type ResetData = {
  email: string;
};

const PasswordReset = () => {
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useForm<ResetData>({
    criteriaMode: "all",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (data: ResetData) => {
    console.log(data);

    try {
      await axiosHelper.resetPassword(data.email);
      history.push("/");
    } catch (error) {
      setError(true);
    }
  };

  return (
    <>
      <div className="page-wrapper page-login">
        <div className="page-container">
          <div className="page-content">
            <div className="top-area">
              <Header showLogo={false} />
            </div>
            <div className="center-area">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 col-lg-6 position-relative">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center tww-container">
                        <img
                          className="tww-image"
                          src={LogoImage}
                          alt="Kickoff Commerciale"
                        />
                      </div>
                    </div>
                    <div className="row px-2 p-md-0">
                      <div className="col-12 white-box pt-3 pb-2 px-3">
                        <div className="row">
                          <div className="col-12">
                            <h2 className="text-title primary pb-2">
                              Password dimenticata?
                            </h2>
                            <div className="text-body">
                              Se hai dimenticato la password per accedere,
                              inserisci la tua mail aziendale nello spazio
                              sottostante e riceverai una mail con le istruzioni
                              per il ripristino.
                            </div>
                          </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="row">
                            <div className="col-12 col-md-12">
                              <input
                                type="email"
                                placeholder="E-mail aziendale"
                                name="email"
                                ref={register({
                                  required: "Campo obbligatorio" as string,
                                  max: 100,
                                  min: 4,
                                  maxLength: 100,
                                })}
                              />
                              <ErrorMessage errors={errors} name="email" />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 d-flex justify-content-start align-items-center"></div>
                            <div className="col-md-6 d-flex justify-content-start align-items-center">
                              <button className="button--submit">
                                RIPRISTINO PASSWORD
                              </button>
                              {error ? (
                                <div className="modal-text error-message">
                                  <br />
                                  {"An error occurred."}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6 d-flex justify-content-between">
                        <div className="help-link">
                          <a onClick={() => history.push("/login")}>
                            Torna al login
                          </a>
                        </div>
                        <div className="help-link">
                          <a
                            href={Constants.INFORMATIVA_PRIVACY_LINK}
                            target="_blank"
                          >
                            Informativa privacy
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-area">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;

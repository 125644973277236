import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import axiosHelper from '../helpers/axiosHelper';
import QuestionComponent from '../components/test/QuestionComponent';
import TestStore, { fetchSubmission, sendSubmission } from '../helpers/TestStore';
import { calculateUserAnswers } from '../helpers/utils';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Test = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submission = TestStore.useState((s) => s.submission);
  const [questions, setQuestions] = useState<Question[]>();
  const [missing, setMissing] = useState(questions?.length || 30);
  const sendit = () => {
    sendSubmission.run(submission);
    handleClose();
  };
  useEffect(() => {
    axiosHelper.logWebAppEvent('Test', null, null);
    window.scrollTo(0, 0);
    fetchSubmission.run();
  }, []);

  useEffect(() => {
    if (submission) {
      setMissing(submission.questions.length - calculateUserAnswers(submission).length);
      if (questions) {
      } else {
        setQuestions(submission.questions!);
      }
    }
  }, [submission, questions]);

  return (
    <>
      <Navigation showLogo={true} />
      <div className='page-wrapper test'>
        <div className='page-container'>
          <div className='page-content'>
            <div className='center-area'>
              <div className='columns-wrapper'>
                {submission && (
                  <div className='box col-12 questions-wrapper'>
                    {submission.questions.map((question: Question) => (
                      <QuestionComponent key={question.id} question={question} />
                    ))}

                    {submission.state === 'E' ? (
                      <></>
                    ) : (
                      <div className='text-center'>
                        <br />
                        {missing > 0 ? (
                          <>
                            {' '}
                            <button className='button--ghost'>{missing} RISPOSTE MANCANTI</button>
                          </>
                        ) : (
                          <>
                            <button className='button--submit' onClick={handleShow}>
                              INVIA TEST
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Attenzione</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Il test, una volta inviato, non potrà essere modificato
                <br />
              </Modal.Body>
              <Modal.Footer>
                <Button className='button--ghost' onClick={handleClose}>
                  Annulla
                </Button>
                <Button className='button--submit' onClick={sendit}>
                  INVIA TEST
                </Button>
              </Modal.Footer>
            </Modal>

            <div className='bottom-area'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Test;

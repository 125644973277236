const Constants = {
  //FIXME controllare prima di mettere in produzione

  //LOCALHOST DEVELOPMENT ENV
  // API_ENDPOINT: "http://localhost/cgi-bin/WebObjects/MMBAApi.woa/-52830/ra",
  API_ENDPOINT: "https://mmba.altera.it/api/v1",
  // LS_ENDPOINT: "http://localhost:8080",

  //WLF DEVELOPMENT ENV
  // API_ENDPOINT: "http://192.168.2.121/cgi-bin/WebObjects/CCApi.woa/-7971/ra",
  // LS_ENDPOINT: "http://192.168.2.121:8080",

  // SUNNY DEVELOPMENT ENV
  //API_ENDPOINT: "http://127.0.0.1/cgi-bin/WebObjects/TSApi.woa/-52830/ra",

  // //PRODUCTION ENV
  // API_ENDPOINT: "https://api.teamsystemdigitalevents.com/v1",
  LS_ENDPOINT: "https://push.teamsystemdigitalevents.com",

  // //TEST ENV
  // API_ENDPOINT: "https://apitest.cchbci-regeneration.it/v1",
  // LS_ENDPOINT: "https://pushtest.cchbci-regeneration.it",

  LS_ADAPTOR: "JNT",

  QUESTIONS_ENDPOINT: "https://media.teamsystemdigitalevents.com/questions",
  AVATAR_EP: "https://media.teamsystemdigitalevents.com/avatars/",
  STATUS_TOPIC_NAME: "status",

  COUNTDOWN_STATUS_NAME: "countdown",
  LIVE_STATUS_NAME: "live",
  END_STATUS_NAME: "end",

  QUESTION_TOPIC_NAME: "question",
  VIDEO_TOPIC_NAME: "video",

  FOLLOWUP_ON_EP: "https://media.teamsystemdigitalevents.com/loghi_on/",
  FOLLOWUP_OFF_EP: "https://media.teamsystemdigitalevents.com/loghi_off/",

  INFORMATIVA_PRIVACY_LINK: "https://www.iubenda.com/privacy-policy/70818099",
  CONDIZIONI_UTILIZZO_LINK: "https://www.teamsystem.com/privacy-policy",
  LIBERATORIA_LINK: "https://www.teamsystem.com/privacy-policy",
  GUIDA_TAGLIE_LINK: "https://www.teamsystem.com/privacy-policy",
  GUIDA_VIDEO_LINK: "https://www.teamsystem.com/privacy-policy",

  TAPPI_PATH: "https://media.teamsystemdigitalevents.com/tappi/",

  INFODESK_PLANARY_IMG1: "https://media.cchbci-regeneration.it/images/INFODESK-plenary-01.jpg",
  INFODESK_PLANARY_IMG2: "https://media.cchbci-regeneration.it/images/INFODESK-plenary-02.jpg",
  INFODESK_PLANARY_IMG3: "https://media.cchbci-regeneration.it/images/INFODESK-plenary-03.jpg",

  REDFLIX_VIDEOS_PATH: "https://media.cchbci-regeneration.it/hbcflix/videos/",
  REDFLIX_DOCS_PATH: "https://media.cchbci-regeneration.it/hbcflix/documents/",
  REDFLIX_THUMBS_PATH: "",
};

export default Constants;

import axiosHelper from '../../helpers/axiosHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { useEffect, useState } from 'react';
import { toggleChecked } from '../../helpers/TestStore';

interface Props {
  answer: Answer;
  hasAnswerCallback: () => void;
}

const AnswerComponent = (props: Props) => {
  const resultsMode = props.answer.isCorrect !== undefined;
  const [rowClass, setRowClass] = useState('answer-row row');

  useEffect(() => {
    if (props.answer.checked) {
      props.hasAnswerCallback();
    }
    if (resultsMode) {
      if (props.answer.isCorrect === true) {
        setRowClass((oldClass) => oldClass + ' correct');
      }
      if (props.answer.checked && !props.answer.isCorrect) {
        setRowClass((oldClass) => oldClass + ' wrong');
      }
    }
  }, [resultsMode, props.answer]);
  return (
    <div
      className={rowClass}
      onClick={() => {
        if (resultsMode) {
          console.log('sono in results mode');
        } else {
          toggleChecked(props.answer.id, props.answer.checked);
        }
      }}>
      <div className='check-block col-1'>{props.answer.checked ? <FontAwesomeIcon size='2x' icon={faCheckSquare} /> : <FontAwesomeIcon size='2x' icon={faSquare} />}</div>
      <div className='answer-block col-11'>{props.answer.answerText}</div>
    </div>
  );
};
export default AnswerComponent;
